/*
  Shown to the owner when only a single purchaser has bought the item and they attempt to unmark it.
  Shown to guests when they attempt to unmark an item they have bought.
*/

import styled from 'styled-components';
import {
  Stack,
  Typography,
  StandardButton,
  CheckCircleIcon,
  checkCircleIconClassNames,
} from '@moonsifttech/design-system';
import { useModal, ModalTypes } from 'src/hooks/useModal';
import { useUnmarkAsBought } from 'src/hooks/useUnmarkAsBought';
import { ModalContent } from 'src/components/core/ModalContent';
import { GoToBuyItemCard } from 'src/components/collection/GoToBuyItemCard';

const UnstyledUnmarkAsBoughtDialog = ({
  collectionId,
  collectionUserId,
  itemId,
  className,
}: {
  collectionId: string;
  collectionUserId: string;
  itemId: string;
  className?: string;
}) => {
  const [ setModal, closeModal ] = useModal();

  const {
    working,
    unmark,
  } = useUnmarkAsBought({
    collectionUID: collectionId,
    collectionUserUID: collectionUserId,
    collectionItemUID: itemId,
    onSuccess: closeModal,
  });

  return (
    <ModalContent className={className} onClose={closeModal}>
      <Typography variant="primary.b26" component="h1">
        <span>Un-mark as bought?</span>
      </Typography>

      <Typography
        className="UnmarkAsBoughtDialog-infoWrapper"
        variant="primary.r12"
        component="p"
      >
        <CheckCircleIcon size="small" />
        <span>Item will become available for others to purchase</span>
      </Typography>
      <Typography variant="primary.r16" component="p">
        If this item is out of stock on the suggested store, it may be
        available elsewhere. If not, please let the registry owner know.
      </Typography>

      <GoToBuyItemCard
        className="UnmarkAsBoughtDialog-productCard"
        collectionId={collectionId}
        collectionUserId={collectionUserId}
        itemId={itemId}
        showLink
      />

      <Stack direction='row' justifyContent='flex-end'>
        <StandardButton
          className="UnmarkAsBoughtDialog-backButton"
          variant="secondary"
          onClick={() => setModal({
            type: ModalTypes.BoughtBy,
            collectionId,
            collectionUserId,
            itemId,
          })}
        >
          Back
        </StandardButton>
        <StandardButton
          variant="primary-white"
          loading={working}
          onClick={() => unmark()}
        >
          Yes, un-mark as bought
        </StandardButton>
      </Stack>
    </ModalContent>
  );
};

export const UnmarkAsBoughtDialog = styled(UnstyledUnmarkAsBoughtDialog)`
  .UnmarkAsBoughtDialog-productCard {
    margin-bottom: 2rem;
  }

  .UnmarkAsBoughtDialog-infoWrapper {
    display: flex;
    align-items: center;

    > .${checkCircleIconClassNames.root} {
      min-width: 16px;
      margin-right: 0.5rem;
    }
  }

  .UnmarkAsBoughtDialog-backButton {
    margin-right: 0.75rem;
  }
`;
