import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';
import { object, string } from 'yup';
import R from 'src/routes';
import {
  Typography,
  Stack,
  ControlledTextField,
  textFieldClassNames,
  CtaButton,
  linkClassNames,
  FormHelperText,
  Form,
  type FormProps,
} from '@moonsifttech/design-system';
import { useSignInProviders } from 'src/hooks/useSignInProviders/useSignInProviders';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = object({
  email: string()
    .email('Must be a valid email.')
    .max(254, 'Max 254 characters.')
    .required('Email is required.'),
  password: string()
    .min(6, 'Password is required.')
    .max(128, 'Maximum 128 characters.')
    .required('Password is required.'),
}).required();

const UnstyledRelinkFacebookForm = ({
  className,
  style,
  isFromApp,
}: {
  className?: string;
  style?: React.CSSProperties;
  isFromApp: boolean;
}) => {
  const match = useRouteMatch(R.SIGNUPFROMEXTENSION);
  const isExtensionSignUp = Boolean(match?.isExact);

  const { relinkApple, relinkGoogle, relinkEmail, isWorking, mainErrors } =
    useSignInProviders(
      isFromApp
        ? R.RELINK_FACEBOOK_MOBILE_CALLBACK
        : isExtensionSignUp
        ? R.POSTINSTALL_ONE
        : R.HOME,
      false,
    );

  const handleSubmit: FormProps<typeof initialValues>['onSubmit'] = (
    { email, password },
    actions,
  ) => {
    relinkEmail(email, password);
    actions.setSubmitting(false);
  };

  return (
    <Form
      className={className}
      style={style}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Stack
        className="RelinkFacebookForm-formContainer"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {mainErrors.email && (
          <FormHelperText error>{mainErrors.email}</FormHelperText>
        )}
        <ControlledTextField
          name="email"
          type="text"
          autoComplete="email"
          placeholder="Email"
          fullWidth
        />
        <ControlledTextField
          className="RelinkFacebookForm-passwordInput"
          name="password"
          type="password"
          autoComplete="new-password"
          placeholder="Create a password"
          fullWidth
        />
        <CtaButton
          className="RelinkFacebookForm-continueButton"
          type="submit"
          variant="primary-black"
          fullWidth
          loading={isWorking.email}
          disabled={isWorking.google || isWorking.apple}
        >
          Continue
        </CtaButton>
        {mainErrors.providers && (
          <FormHelperText error>{mainErrors.providers}</FormHelperText>
        )}

        <Typography
          variant="primary.b16"
          align="center"
          style={{ marginBottom: '1.25rem', marginTop: '1.25rem' }}
        >
          Or switch to Apple / Google login.
        </Typography>

        <CtaButton
          className="RelinkFacebookForm-googleButton"
          variant="primary-white"
          startIcon={
            <img
              width={20}
              height={20}
              src={`${process.env.REACT_APP_STATIC_URL}/logos/googleSignupLogo.svg`}
              alt="Google logo"
            />
          }
          fullWidth
          loading={isWorking.google}
          disabled={isWorking.email || isWorking.apple}
          onClick={relinkGoogle}
        >
          Switch to Google login
        </CtaButton>
        <CtaButton
          className="RelinkFacebookForm-appleButton"
          variant="primary-white"
          startIcon={
            <img
              width={20}
              height={20}
              src={`${process.env.REACT_APP_STATIC_URL}/logos/apple_logo.svg`}
              alt="Apple logo"
            />
          }
          fullWidth
          loading={isWorking.apple}
          disabled={isWorking.email || isWorking.google}
          onClick={relinkApple}
        >
          Switch to Apple login
        </CtaButton>
      </Stack>
    </Form>
  );
};

export const RelinkFacebookForm = styled(UnstyledRelinkFacebookForm)`
  .RelinkFacebookForm-formContainer {
    min-width: 320px;

    > .${textFieldClassNames.root} {
      margin-bottom: 10px;
    }

    > .RelinkFacebookForm-passwordInput {
      margin-bottom: 16px;
    }

    .RelinkFacebookForm-googleButton {
      margin-bottom: 6px;
    }

    .RelinkFacebookForm-appleButton {
      margin-bottom: 20px;
    }

    .RelinkFacebookForm-terms {
      margin-bottom: 10px;
    }

    .RelinkFacebookForm-login .${linkClassNames.root} {
      font-size: 14px;
      font-weight: 700;
      text-decoration: none;
    }
  }
`;
