/*

TODO: optimisations with regards to selctors,

*/

import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProductTile } from 'src/mvp22/tile-components/ProductTile';
import { ProductTileContainerProps } from './ProductTile.types';
import R from 'src/routes';
import { product_membership_redux_set } from 'src/mvp22/redux-components/reducers/firestore_product_membership';
import { RootState } from 'src/index';
import { ServicesContext } from 'src/ServicesContext';
import get_site_uid from 'src/mvp22/core-components/get_site_uid';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';
import { CollectionItem } from 'src/types/models/collectionItem.model';
import { ModalTypes, ProductData } from 'src/types';
import { useModal } from 'src/hooks/useModal';
import { useSetPurchased } from './useSetPurchased';
import { useReaction } from './useReaction';
import { useDeleteProduct } from './useDeleteProduct';
import { useItem } from 'src/hooks/useItem';

const Container: React.FC<ProductTileContainerProps> = ({
  isOrganising,
  itemUID,
  collectionUserUID,
  collectionUID,
  showProductModal: showProductModalProps,
  viewingData,
  choosingCoverImage,
  coverImageDict,
  selectCover,
}) => {
  const productDataStoreAs = getCollectionItemStoreAs(
    collectionUID,
    collectionUserUID,
    itemUID,
  );
  // Redux Selectors:
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const isMine = authUserUID === collectionUserUID;
  const collectionItem = useSelector<RootState, CollectionItem | null>(
    (state) => {
      const listOfOneOrNone = state.db.single_collection_item_list.data.filter(
        (x) => x.id === itemUID,
      );
      return listOfOneOrNone.length === 1 ? listOfOneOrNone[0] : null;
    },
  );
  // Broad selector, will re-render when this changes but will need to in most cases so leaving for now.
  const productData = useSelector<RootState, ProductData | undefined | null>(
    (state) =>
      state.productData ? state.productData[productDataStoreAs] : undefined,
  );
  const imageList = productData?.imageList;
  // Is product saved to this user's collections?
  // Only relevent when viewing other's collections.
  const isSaved = useSelector<RootState, boolean>((state) =>
    state.db.single_collection_product_is_saved.map[productDataStoreAs]
      ?.length > 0
      ? true
      : false,
  );

  // Custom Hooks to separate the code out a bit:
  const { isPurchased, addOrRemoveProductToPurchased, purchasedWorkingValue } =
    useSetPurchased(productDataStoreAs, productData, collectionItem);
  const { reaction, reactProduct, upvoteWorkingValue, downvoteWorkingValue } =
    useReaction(collectionUserUID, collectionUID, itemUID);
  const { deleteProduct, deleteWorkingValue } = useDeleteProduct(
    collectionUserUID,
    collectionUID,
    itemUID,
  );

  /*

  OPENING MODALS:
  Consider moving to it's own hook

  */
  // Loading a modal makes the top right dots enter loading state whilst query is run
  // e.g. for product in other collections.
  const [loadingModal, setloadingModal] = useState(false);
  // Redux Dispatches:
  const dispatch = useDispatch();
  const { firebaseMVP22 } = useContext(ServicesContext);
  const history = useHistory();
  // Working dict in redux in case remounting:
  const productMembershipSet = (...data: any) =>
    dispatch(product_membership_redux_set(...data));
  const [setModal] = useModal();
  const loadProductMembership = async (event: MouseEvent) => {
    event.preventDefault();
    if (authUserUID !== null) {
      if (collectionItem && imageList && imageList[imageIndex]) {
        setloadingModal(true);
        const callback = () => {
          setModal({
            type: ModalTypes.CollectionMembership,
            collectionUID,
            collectionUserUID,
            productImage: imageList[imageIndex],
            collectionItem,
          });
        };
        // Set the data in redux:
        productMembershipSet(
          collectionItem.page_url,
          firebaseMVP22,
          authUserUID,
          callback,
        );
        setloadingModal(false);
      }
    } else {
      history.push(R.SIGNUP);
    }
    return false;
  };

  const showProductModal = (event: MouseEvent) => {
    if (!isOrganising) {
      showProductModalProps(true, itemUID);
    }
    event.preventDefault();
    return false;
  };

  const showProductPriceUpdateModal = (event: MouseEvent) => {
    event.preventDefault();
    if (imageList && imageList[imageIndex]) {
      setModal({
        type: ModalTypes.ProductUpdates,
        collectionUID,
        collectionUserUID,
        itemUID,
        productImage: imageList[imageIndex],
      });
    }
    return false;
  };

  /*

  PRODUCT IMAGES:
  Consider moving to it's own hook

  */
  // STATE
  const [showArrows, setShowArrows] = useState(false); // Arrows on image
  const [showReactionsBar, setShowReactionsBar] = useState(false); // footer reactions bar
  const [imageIndex, setImageIndex] = useState(0); //image currently showing
  const handleMouseoverImage = (value: boolean) => {
    // Determine if we show image arrows or not:
    // Only show more images if there is likely more than one that is NOT the saved image!
    if (!isOrganising) {
      setShowReactionsBar(value);
      if (
        imageList &&
        imageList.filter((x) => !x.includes('moonimage_')).length > 0
      ) {
        setShowArrows(value);
      }
    }
  };

  // Which product image to show:
  const changeViewingProductImage = (indexModifier: number) => {
    if (imageList) {
      var imageIndex_out = imageIndex + indexModifier;
      if (imageIndex_out >= imageList.length) {
        imageIndex_out = 0;
      } else if (imageIndex_out < 0) {
        imageIndex_out = imageList.length - 1;
      }
      setImageIndex(imageIndex_out);
    }
  };
  // If the specified default image changes, we want to change the selected image:
  // if the specified default image changes (i.e. in product modal), then move to that one so that it looks like we actually have done something!
  // image index is defaulted to the one selected by arrows.
  useEffect(() => {
    if (collectionItem && collectionItem.product_image && imageList) {
      const setToIndex = imageList.findIndex(
        (x) => collectionItem.product_image === x,
      );
      if (setToIndex >= 0) setImageIndex(setToIndex);
    }
  }, [collectionItem, imageList]);

  // Convenience function for determining if product used as collection cover image:
  const invertCoverImageDict = () => {
    const newDict: {
      [x: string]: number;
    } = {};
    if (choosingCoverImage) {
      // Keys of objects are strings...
      Object.keys(coverImageDict)
        .map(Number)
        .forEach((numberKey) => {
          newDict[coverImageDict[numberKey]] = numberKey;
        });
    }
    return newDict;
  };

  // Check if the collection is a registry and pass it down to all product tiles.
  const isRegistry = useSelector<RootState, boolean>(
    ({ db }) => db.single_collection.data?.is_registry ?? false,
  );

  // Whether the owner of a registry collection is revealing who's bought what.
  const isRevealing = useSelector<RootState, boolean>((state) => {
    return state.collectionRegistryUi.isRevealing;
  });

  const { boughtByUser, someBought, exhausted } = useItem(itemUID);

  const { analytics } = useContext(ServicesContext);
  const recordEvent = analytics.recordEvent;

  // Map to props:
  const selectedCoverImage = invertCoverImageDict()[itemUID];
  const productImageList = imageList ?? [];
  const thisProductImage = productImageList[imageIndex] ?? '';
  const description = productData ? productData.description : null;
  const lastUpdated = productData ? productData.lastUpdated : null;
  const domain = collectionItem ? get_site_uid(collectionItem.page_url) : null;
  const price = productData ? productData.price : null;
  const priceChange = productData ? productData.priceChange : null;
  const isLoaded = productData && collectionItem;
  const isWorkingPurchased = purchasedWorkingValue !== undefined;
  const isDeleting = deleteWorkingValue !== undefined;
  const isWorkingUpvote = upvoteWorkingValue !== undefined;
  const isWorkingDownvote = downvoteWorkingValue !== undefined;
  const isBGProcessing = productData ? productData.isBGProcessing : undefined;
  const quantity = collectionItem?.quantity ?? 1;
  const purchased = collectionItem?.purchased ?? 0;
  return (
    <ProductTile
      {...{
        authUserUID,
        isMine,
        isLoaded,
        isRegistry,
        isRevealing,
        boughtByUser,
        someBought,
        exhausted,
        priceChange,
        viewingData,
        isWorkingPurchased,
        isWorkingUpvote,
        isWorkingDownvote,
        lastUpdated,
        isPurchased,
        showArrows,
        showReactionsBar,
        isOrganising,
        selectedCoverImage,
        choosingCoverImage,
        url: collectionItem?.page_url,
        description,
        price,
        quantity,
        purchased,
        domain,
        isSaved,
        thisProductImage,
        imageList,
        isBGProcessing,
        loadingModal,
        collectionItem,
        collectionUserUID,
        collectionUID,
        // Functions:
        deleteProduct,
        reactProduct,
        addOrRemoveProductToPurchased,
        handleMouseoverImage,
        selectCover,
        changeViewingProductImage,
        showProductModal,
        loadProductMembership,
        showProductPriceUpdateModal,
        itemUID,
        isDeleting,
        reaction,
        recordEvent,
      }}
    />
  );
};

export { Container as ProductTile };
