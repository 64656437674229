import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import R from 'src/routes';
import { RootState } from 'src';
import {
  Paper,
  Stack,
  Typography,
  CtaButton,
} from '@moonsifttech/design-system';
import { RelinkFacebookForm } from 'src/components/auth/RelinkFacebookForm';
import { useSignInProviders } from 'src/hooks/useSignInProviders/useSignInProviders';

const UnstyledRelinkFacebook = ({
  className,
  style,
  isFromApp,
}: {
  className?: string;
  style?: React.CSSProperties;
  isFromApp: boolean;
}) => {
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );

  const { handleFacebookAuth, isWorking } = useSignInProviders(
    isFromApp ? R.RELINK_FACEBOOK_APP : R.RELINK_FACEBOOK_WEB,
    false,
    false,
  );

  return (
    <Stack
      className={className}
      style={style}
      justifyContent="center"
      alignItems="center"
    >
      <Helmet>
        <title>Relink your account - Moonsift</title>
      </Helmet>
      <Paper className="RelinkFacebook-paper">
        <Stack
          className="RelinkFacebook-container"
          direction="column"
          alignItems="center"
        >
          <Stack className="RelinkFacebook-logo" direction="row">
            <img
              className="RelinkFacebook-logoImage"
              src={`${process.env.REACT_APP_STATIC_URL}/logos/logo.svg`}
              alt="Monsift logo"
            />
            <Typography
              className="RelinkFacebook-logoText"
              variant="branded.b42"
            >
              MOONSIFT
            </Typography>
          </Stack>
          {authUserUID != null ? (
            <>
              <Stack
                justifyContent="center"
                className="RelinkFacebook-explainerContainer"
              >
                <Typography
                  variant="primary.b16"
                  align="center"
                  style={{ marginBottom: '1rem' }}
                >
                  Facebook login can no longer be supported.
                </Typography>
                <Typography
                  variant="primary.r16"
                  align="center"
                  style={{ marginBottom: '1rem' }}
                >
                  Don't worry, your profile and everything you've saved to
                  Moonsift is still here.
                </Typography>
                <Typography
                  variant="primary.r16"
                  align="center"
                  style={{ marginBottom: '1.5rem' }}
                >
                  Simply choose an email and password to log into your account
                  going forward.
                </Typography>

                <RelinkFacebookForm
                  style={{ marginBottom: '2rem' }}
                  isFromApp={isFromApp}
                />

                <Typography
                  variant="primary.r14"
                  align="center"
                  style={{ marginBottom: '1rem' }}
                >
                  If you have any concerns or questions please feel free to
                  email us at{' '}
                  <a href="mailto:hello@moonsift.com">hello@moonsift.com</a>
                </Typography>
              </Stack>
            </>
          ) : (
            <Stack
              justifyContent="center"
              className="RelinkFacebook-explainerContainer"
            >
              <Typography
                variant="primary.b16"
                align="center"
                style={{ marginBottom: '1rem' }}
              >
                Due to persistent issues with Facebook's login service we need
                to provide a new login method for everyone who uses it.
              </Typography>
              <Typography
                variant="primary.r16"
                align="center"
                style={{ marginBottom: '1rem' }}
              >
                Sorry for the inconvenience.
              </Typography>
              <Typography
                variant="primary.r16"
                align="center"
                style={{ marginBottom: '1rem' }}
              >
                Please login with your Facebook account one more time and then
                you'll get to choose a new log in method to use going forward.
              </Typography>

              <CtaButton
                style={{ marginBottom: '2rem' }}
                variant="primary-white"
                startIcon={
                  <img
                    width={20}
                    height={20}
                    src={`${process.env.REACT_APP_STATIC_URL}/logos/facebookSignupLogo.svg`}
                    alt="Facebook logo"
                  />
                }
                fullWidth
                loading={isWorking.facebook}
                onClick={handleFacebookAuth}
              >
                Login with Facebook
              </CtaButton>

              <Typography
                variant="primary.r14"
                align="center"
                style={{ marginBottom: '1rem' }}
              >
                If you have any concerns or questions please feel free to email
                us at <a href="mailto:hello@moonsift.com">hello@moonsift.com</a>
              </Typography>
            </Stack>
          )}
        </Stack>
      </Paper>
    </Stack>
  );
};

export const RelinkFacebook = styled(UnstyledRelinkFacebook)`
  width: 100%;
  height: 100vh;
  background: var(--grey-1);

  .RelinkFacebook-paper {
    padding: 36px 14px;
    width: 480px;
  }

  .RelinkFacebook-logo {
    margin-bottom: 36px;
    align-items: center;

    .RelinkFacebook-logoImage {
      height: 50px;
    }

    .RelinkFacebook-logoText {
      padding: 8px 0 0 4px;
    }
  }

  .RelinkFacebook-explainerContainer {
    max-width: 320px;
  }
`;
