import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  UserCredential,
  GoogleAuthProvider,
  OAuthProvider,
  EmailAuthProvider,
  linkWithPopup,
  linkWithCredential,
  getAuth,
  User,
} from 'firebase/auth';
import { authRedirectSearchParam } from 'src/config';
import { firestore_user_set_up_redux } from 'src/mvp22/redux-components/reducers/firestore_user_set_up';
import { ServicesContext } from 'src/ServicesContext';
import { ActionNames } from 'src/types';
import { UserDataAction } from 'src/types/models/userData.model';
import { UIAction } from 'src/types/models/ui.model';
import { IsWorking, MainErrors } from './useSignInProviders.types';
import { RootState } from 'src';
import R from 'src/routes';

function getEmailErrorMessage(error: Error & { code?: string }): string {
  switch (error.code) {
    case 'auth/invalid-email':
      return "Sorry, that email doesn't look valid.";
    case 'auth/user-not-found':
      return "The email address you've entered doesn't match any account. Please sign up for an account.";
    case 'auth/wrong-password':
      return 'Sorry, that password is not correct. Please try again or try logging in with Facebook, Google or Apple below.';
    case 'auth/email-already-in-use':
    case 'auth/email-already-exists':
    case 'auth/provider-already-linked':
      return 'This email is already in use, try logging in instead.';
    default:
      return error.message;
  }
}

function getRelinkProviderErrorMessage(
  provider: string,
  error: Error & { code?: string },
): string {
  switch (error.code) {
    case 'auth/email-already-in-use':
    case 'auth/email-already-exists':
    case 'auth/provider-already-linked':
      return `This ${provider} profile is already in use, try logging in instead.`;
    default:
      return `Sorry, we failed to relink your account with ${provider}. Error: ${error.message}`;
  }
}

export const useSignInProviders = (
  defaultRedirectRoute: string,
  homeRedirect: boolean = true, // NOTE: only for facebook relink flow, should be removed later
  shouldRedirect: boolean = true,
) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { search } = location;
  const { auth, analytics, firebaseMVP22 } = useContext(ServicesContext);
  const isProvisioned = useSelector<RootState, true | undefined>(
    (state) => state.firestore_user_owner?.provisioned,
  );
  const [facebookLogin, setFacebookLogin] = useState(false);

  const searchParams = new URLSearchParams(search);
  const searchParamsRedirect = searchParams.has(authRedirectSearchParam)
    ? searchParams.get(authRedirectSearchParam)
    : null;
  const redirectRoute = searchParamsRedirect ?? defaultRedirectRoute;

  const [isWorking, setIsWorking] = useState<IsWorking>({
    email: false,
    facebook: false,
    google: false,
    apple: false,
  });
  const [mainErrors, setMainErrors] = useState<MainErrors>({
    email: '',
    providers: '',
  });

  // Function to set the not provisionioned message off until we have had a chance to update the user data.
  const blockNotProvisionedMessage = useCallback((): void => {
    dispatch<UserDataAction>({
      type: ActionNames.BEGAN_SIGN_IN,
      payload: undefined,
    });
  }, [dispatch]);

  // Check whether the user just signed up and set a flag if so
  const checkIfJustSignedUp = useCallback(
    (userCredential: UserCredential): void => {
      const { creationTime } = userCredential.user.metadata;

      if (
        creationTime &&
        Date.now() - new Date(creationTime).getTime() < 5000
      ) {
        dispatch<UIAction>({
          type: ActionNames.UI_SIGNED_UP_IN_SESSION_SET,
          payload: undefined,
        });
      }
    },
    [dispatch],
  );

  const handleUserEmailSignIn = useCallback(
    ({ email, password }) => {
      setIsWorking((prevState) => ({ ...prevState, email: true }));

      blockNotProvisionedMessage();

      // Sign in to an existing account
      auth
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          setIsWorking((prevState) => ({ ...prevState, email: false }));
          if (shouldRedirect) {
            history.push(redirectRoute);
          }
        })
        .catch((error) => {
          setIsWorking((prevState) => ({ ...prevState, email: false }));
          setMainErrors({
            providers: '',
            email: getEmailErrorMessage(error),
          });
        });
    },
    [auth, blockNotProvisionedMessage, history, redirectRoute, shouldRedirect],
  );

  const handleUserEmailSignUp = useCallback(
    ({ fullName, email, password }) => {
      setIsWorking((prevState) => ({ ...prevState, email: true }));

      // Create auth user and provision it:
      auth
        .createUserWithEmailAndPassword(fullName, email, password)
        //If successful, will be provisioned so go straight to next page after loading the data:
        .then((_userCredential) => {
          return dispatch(firestore_user_set_up_redux(firebaseMVP22));
        })
        .catch((error) => {
          setIsWorking((prevState) => ({ ...prevState, email: false }));
          setMainErrors({
            providers: '',
            email: getEmailErrorMessage(error),
          });
        });
    },
    [auth, dispatch, firebaseMVP22],
  );

  useEffect(() => {
    if (isProvisioned && !facebookLogin && homeRedirect) {
      dispatch<UIAction>({
        type: ActionNames.UI_SIGNED_UP_IN_SESSION_SET,
        payload: undefined,
      });
      setIsWorking((prevState) => ({ ...prevState, email: false }));
      history.push(redirectRoute);
    }
  }, [
    dispatch,
    history,
    redirectRoute,
    isProvisioned,
    facebookLogin,
    homeRedirect,
  ]);

  const handleFacebookAuth = useCallback(() => {
    // Send analytics
    analytics.recordEvent('WebApp:FacebookAuth:Pressed');

    setIsWorking((prevState) => ({ ...prevState, facebook: true }));

    blockNotProvisionedMessage();

    auth
      .signInWithProvider('facebook')
      .then((userCredential) => {
        setFacebookLogin(true);
        checkIfJustSignedUp(userCredential);
        // Will go to not provisioned page OR to required page.
        setIsWorking((prevState) => ({ ...prevState, facebook: false }));
        if (shouldRedirect) {
          history.push(R.RELINK_FACEBOOK_WEB);
        }
      })
      .catch((error) => {
        setIsWorking((prevState) => ({ ...prevState, facebook: false }));
        setMainErrors({
          email: '',
          providers: `Sorry, sign in via Facebook failed. Error: ${error.message}`,
        });
      });
  }, [
    analytics,
    auth,
    blockNotProvisionedMessage,
    checkIfJustSignedUp,
    history,
    shouldRedirect,
  ]);

  const handleGoogleAuth = useCallback(() => {
    // Send analytics
    analytics.recordEvent('WebApp:GoogleAuth:Pressed');

    setIsWorking((prevState) => ({ ...prevState, google: true }));

    blockNotProvisionedMessage();

    auth
      .signInWithProvider('google')
      .then((userCredential) => {
        checkIfJustSignedUp(userCredential);
        // Will go to not provisioned page OR to required page.
        setIsWorking((prevState) => ({ ...prevState, google: false }));
        if (shouldRedirect) {
          history.push(redirectRoute);
        }
      })
      .catch((error) => {
        setIsWorking((prevState) => ({ ...prevState, google: false }));
        setMainErrors({
          email: '',
          providers: `Sorry, sign in via Google failed. Error: ${error.message}`,
        });
      });
  }, [
    analytics,
    auth,
    blockNotProvisionedMessage,
    checkIfJustSignedUp,
    history,
    redirectRoute,
    shouldRedirect,
  ]);

  const handleAppleAuth = useCallback(() => {
    // Send analytics
    analytics.recordEvent('WebApp:AppleAuth:Pressed');

    setIsWorking((prevState) => ({ ...prevState, apple: true }));

    blockNotProvisionedMessage();

    auth
      .signInWithProvider('apple')
      .then((userCredential) => {
        checkIfJustSignedUp(userCredential);
        // Will go to not provisioned page OR to required page.
        setIsWorking((prevState) => ({ ...prevState, apple: false }));
        if (shouldRedirect) {
          history.push(redirectRoute);
        }
      })
      .catch((error) => {
        setIsWorking((prevState) => ({ ...prevState, apple: false }));
        setMainErrors({
          email: '',
          providers: `Sorry, sign in via Apple failed. Error: ${error.message}`,
        });
      });
  }, [
    analytics,
    auth,
    blockNotProvisionedMessage,
    checkIfJustSignedUp,
    history,
    redirectRoute,
    shouldRedirect,
  ]);

  /* Temporary until FB auth users are flushed out of the system */
  const relinkGoogle = async () => {
    setIsWorking((prevState) => ({ ...prevState, google: true }));
    blockNotProvisionedMessage();

    const user = getAuth().currentUser as User;
    try {
      const result = await linkWithPopup(user, auth.google);

      const credentials = GoogleAuthProvider.credentialFromResult(result);
      if (shouldRedirect) {
        history.push(redirectRoute);
      }
      return credentials;
    } catch (err) {
      setMainErrors({
        email: '',
        providers: getRelinkProviderErrorMessage('Google', err as Error),
      });
    } finally {
      setIsWorking((prevState) => ({ ...prevState, google: false }));
    }
  };

  const relinkApple = async () => {
    setIsWorking((prevState) => ({ ...prevState, apple: true }));
    blockNotProvisionedMessage();

    const user = getAuth().currentUser as User;
    try {
      const result = await linkWithPopup(user, auth.apple);

      const credentials = OAuthProvider.credentialFromResult(result);
      if (shouldRedirect) {
        history.push(redirectRoute);
      }
      return credentials;
    } catch (err) {
      setMainErrors({
        email: '',
        providers: getRelinkProviderErrorMessage('Apple', err as Error),
      });
    } finally {
      setIsWorking((prevState) => ({ ...prevState, apple: false }));
    }
  };

  const relinkEmail = async (email: string, password: string) => {
    setIsWorking((prevState) => ({ ...prevState, email: true }));
    blockNotProvisionedMessage();

    const credential = EmailAuthProvider.credential(email, password);
    const user = getAuth().currentUser as User;
    try {
      const result = await linkWithCredential(user, credential);
      if (shouldRedirect) {
        history.push(redirectRoute);
      }
      return result;
    } catch (err) {
      setMainErrors({
        providers: '',
        email: getEmailErrorMessage(err as Error),
      });
    } finally {
      setIsWorking((prevState) => ({ ...prevState, email: false }));
    }
  };
  /* Temporary until FB auth users are flushed out of the system */

  return {
    handleUserEmailSignUp,
    handleUserEmailSignIn,
    handleFacebookAuth,
    handleGoogleAuth,
    handleAppleAuth,
    relinkApple,
    relinkGoogle,
    relinkEmail,
    isWorking,
    mainErrors,
  };
};
