/*
  Shown when the user clicks the "Go to item" button. Warns about marking purchases before leaving the site.
*/

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  Typography,
  StandardButton,
  LaunchIcon,
  LorryIcon,
} from '@moonsifttech/design-system';
import { RootState } from 'src/index';
import { useModal, ModalTypes } from 'src/hooks/useModal';
import { withExhaustedCheck } from 'src/components/collection/withExhaustedCheck';
import { ModalContent } from 'src/components/core/ModalContent';
import { GiftListInfo } from 'src/components/collection/GiftListInfo';
import { AffiliateLink } from 'src/components/core/AffiliateLink';

type Props = {
  collectionId: string;
  collectionUserId: string;
  itemId: string;
  className?: string;
}

const UnstyledGoToItemDialog = ({
  collectionId,
  collectionUserId,
  itemId,
  className,
}: Props) => {
  const [ setModal, closeModal ] = useModal();

  const ownerName = useSelector<RootState, string>(({ db }) => {
    return db.user_public.map[collectionUserId]?.name ?? 'the owner';
  });
  const ownerNote = useSelector<RootState, string>(({ db }) => {
    return db.collection_registry_notes.data?.[itemId]?.note ?? '';
  });
  const deliveryInfo = useSelector<RootState, string | undefined>(({ db }) => {
    return db.single_collection.data?.delivery_info;
  });

  const items = useSelector(
    (state: RootState) => state.db.single_collection_item_list.data,
  );
  const item = items
    .find((item) => item.id === itemId);
  const requestedQuantity = item?.quantity ?? 1;


  return (
    <ModalContent className={className} onClose={closeModal}>
      <Typography variant="primary.b26" component="h1">
        Important: Remember to mark it as bought
      </Typography>

      <Typography variant="primary.r16" component="p">
        To avoid duplicate purchases, if you buy this item, please return to
        Moonsift to mark it as bought.
      </Typography>

      <div className="GoToItemDialog-infoWrapper">
        {ownerNote && (
          <GiftListInfo className="GoToItemDialog-noteWrapper">
            <Typography
              variant="primary.l14"
              component="p"
              style={{ marginBottom: '4px' }}
            >
              {`A note from ${ownerName}:`}
            </Typography>
            <Typography variant="primary.r14">
              {ownerNote}
            </Typography>
          </GiftListInfo>
        )}
        {deliveryInfo && (
          <div className="GoToItemDialog-deliveryInfoWrapper">
            <div
              className="GoToItemDialog-clickable"
              onClick={() => setModal({
                type: ModalTypes.DeliveryInfo,
                collectionId,
                collectionUserId,
                back: () => setModal({
                  type: ModalTypes.GoToItem,
                  collectionId,
                  collectionUserId,
                  itemId,
                })
              })}
            >
              <LorryIcon
                size={20}
              />
              <Typography
                variant="primary.r14"
                component="span"
                className="GoToItemDialog-deliveryInfoUnderline"
              >
                View delivery info
              </Typography>
            </div>
            <Typography
              variant="primary.r14"
              component="span"
            >
              &nbsp;if you'd like to send directly
            </Typography>
          </div>
        )}
      </div>

      {requestedQuantity > 1 && (
        <div className='QuantityDisplay-wrapper'>
          <Typography
            className='QuantityDisplay-quantity'
            component='span'
            variant='primary.m16'
            color='grey.2'
          >
            Requested: {requestedQuantity}
          </Typography>
          <Typography
            className='QuantityDisplay-needed'
            component='span'
            variant='primary.m16'
            color='background.purple'
          >
            Still needs: {requestedQuantity - (item?.purchased ?? 0)}
          </Typography>
        </div>
      )}

        <AffiliateLink
          collectionItem={{
            collectionId,
            collectionUserId,
            itemId,
          }}
        >
          <StandardButton
            className="GoToItemDialog-button"
            variant="primary-white"
            fullWidth
            endIcon={<LaunchIcon />}
          >
            Continue to item
          </StandardButton>
        </AffiliateLink>

      <StandardButton
        className="GoToItemDialog-button"
        variant="secondary"
        fullWidth
        onClick={() => setModal({
          type: ModalTypes.MarkAsBought,
          collectionId,
          collectionUserId,
          itemId
        })}
      >
        Mark as bought
      </StandardButton>

      <Typography variant="primary.r14" component="p">
        'Mark as bought' now if you intend to purchase it right away.
      </Typography>
    </ModalContent>
  );
};

const StyledGoToItemDialog = styled(UnstyledGoToItemDialog)`
  .GoToItemDialog-infoWrapper {
    margin-bottom: 1rem;
  }
  
  .GoToItemDialog-noteWrapper {
    margin-bottom: 1rem;
  }

  .GoToItemDialog-deliveryInfoWrapper {
    display: flex;
    align-items: center;
  }

  .GoToItemDialog-clickable {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .GoToItemDialog-deliveryInfoUnderline {
    text-decoration: underline;
    margin-left: 6px;
  }

  .GoToItemDialog-button {
    margin-bottom: 0.75rem;
  }

  .QuantityDisplay-wrapper {
    margin-bottom: 1.5rem;
  }

  .QuantityDisplay-quantity {
    margin-right: 1rem;
  }
`;

export const GoToItemDialog = withExhaustedCheck<Props>(StyledGoToItemDialog);
