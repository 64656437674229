import { VFC } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Stack, Typography } from '@moonsifttech/design-system';

import { Page } from 'src/components/common/Page';
import { Link } from 'src/components/core/Link';
import {
  categories,
  categoryStructure,
  GUIDES,
  pageTitles,
  SEORoutes,
} from 'src/routes';
import { SitemapProps } from './Sitemap.types';

const GuidesTyped = GUIDES as Partial<Record<string, Record<string, string>>>;

const UnstyledSitemap: VFC<SitemapProps> = ({ className, style }) => {
  return (
    <>
      <Helmet>
        <title>Moonsift: Sitemap</title>
        <meta name="description" content="Site map for Moonsift" />
      </Helmet>
      <Page className={className} style={style}>
        <Stack alignItems="center">
          <Typography
            className="HowToSave-heading"
            variant="primary.b42"
            component="h1"
          >
            Sitemap
          </Typography>
          <Stack justifyContent="center" className="SitemapOuterStack">
            <Stack direction="row" wrap="wrap">
              {Object.values(categories).map((categoryKey) => {
                const categoryName = categoryStructure[categoryKey].title;
                const pagesInCategory = SEORoutes.filter(
                  (pageEntry) => pageEntry[3] === categoryKey,
                );
                const guidesKey = categoryKey.replace('GUIDES_', '');
                const guidesCategory = GuidesTyped[guidesKey];
                const guidesPages = guidesCategory
                  ? Object.keys(guidesCategory ?? {}).map((pageTitle) => [
                      guidesCategory[pageTitle],
                      'monthly',
                      0.45,
                      guidesKey,
                      pageTitle,
                    ])
                  : [];
                const pagesToShowInCategory = [
                  ...pagesInCategory,
                  ...guidesPages,
                ];
                return (
                  <Stack direction="column">
                    <section className="SiteMapSection">
                      <Typography
                        variant="editorial.r22"
                        component="h2"
                        align="left"
                      >
                        {categoryName}
                      </Typography>
                      <ul>
                        {pagesToShowInCategory.map((page) => (
                          <li>
                            <Typography
                              variant="primary.l16"
                              component="p"
                              align="left"
                            >
                              <Link
                                className="SiteMap-link"
                                to={page[0] as string}
                              >
                                {page[4] ?? pageTitles[page[0]]}
                              </Link>
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    </section>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Page>
    </>
  );
};

export const Sitemap = styled(UnstyledSitemap)`
  overflow-x: hidden;
  align-items: center;
  section.Sitemap-backgroundGrey {
    background-color: var(--grey-7);
  }
  .SitemapOuterStack {
    width: 100%;
  }
  margin: 0px 24px;
  .SiteMapSection {
    width: 300px;
    a {
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
    li {
      list-style-type: none;
    }
    margin: 12px;
  }
`;
