import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Paper,
  paperClassNames,
  Stack,
  Typography,
  LaunchIcon,
} from '@moonsifttech/design-system';
import { RootState } from 'src/index';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';
import { AffiliateLink } from 'src/components/core/AffiliateLink';

const UnstyledGoToBuyItemCard = ({
  collectionId,
  collectionUserId,
  itemId,
  showLink = false,
  className,
  style,
}: {
  collectionId: string;
  collectionUserId: string;
  itemId: string;
  showLink?: boolean;
  className?: string;
  style?: React.CSSProperties;
}) => {
  const productDataStoreAs = getCollectionItemStoreAs(
    collectionId,
    collectionUserId,
    itemId,
  );

  // Get collection item's data.
  const imageSource = useSelector<RootState, string | undefined>(
    ({ productData }) => {
      return productData?.[productDataStoreAs].imageList[0];
    },
  );
  const description = useSelector<RootState, string | undefined>(
    ({ productData }) => {
      return productData?.[productDataStoreAs].description;
    },
  );

  return (
    <Paper className={className} style={style} variant="outlined">
      <Stack direction="row" spacing={16}>
        <img
          className="GoToBuyItemCard-productCardImage"
          src={imageSource}
          alt={description}
        />
        <Stack direction="column" justifyContent="center">
          <Typography
            className="GoToBuyItemCard-productCardDescription"
            variant="primary.r14"
            component="h2"
          >
            {description}
          </Typography>
          {showLink && (
            <AffiliateLink
              collectionItem={{
                collectionId,
                collectionUserId,
                itemId,
              }}
            >
              <Stack direction="row" alignItems="center" spacing={6}>
                <Typography variant="primary.b14">
                  Go to buy item
                </Typography>
                <LaunchIcon size="small" />
              </Stack>
            </AffiliateLink>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export const GoToBuyItemCard = styled(UnstyledGoToBuyItemCard)`
  padding: 12px;

  /* FIXME: We need to update to sort this specificity issue in the design system. */
  &.${paperClassNames.variantOutlined} {
    border: ${({ theme }) => theme.fns.getBorder('secondary')};
  }

  .GoToBuyItemCard-productCardImage {
    max-width: 120px;
  }

  .GoToBuyItemCard-productCardDescription {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 8px;
  }
`;

