import { VFC } from 'react';
import styled from 'styled-components';
import {
  AddCircleIcon,
  ButtonBase,
  Link,
  Stack,
  Typography,
} from '@moonsifttech/design-system';
import { AffiliateLink } from 'src/components/core/AffiliateLink';

import { EmptyMessageOwnCollectionProps } from './EmptyMessageOwnCollection.types';
import R, { EXTERNAL } from 'src/routes';

const UnstyledEmptyMessageOwnCollection: VFC<
  EmptyMessageOwnCollectionProps
> = ({ className, style, isCreator, goToAddItem }) => {
  return (
    <div className={className} style={style}>
      <Stack className="EmptyMessageOwnCollection-addItemButtonContainer">
        <ButtonBase
          data-testid="EmptyMessageOwnCollection-addItemButton"
          className="EmptyMessageOwnCollection-addItemButton"
          onClick={goToAddItem}
        >
          <Stack direction="column" alignItems="center">
            <AddCircleIcon className="EmptyMessageOwnCollection-addCircleIcon" />
            <Typography
              variant="primary.l16"
              component="p"
              align="center"
              className="EmptyMessageOwnCollection-addItemText"
            >
              Add Item
            </Typography>
          </Stack>
        </ButtonBase>
      </Stack>
      <Stack
        direction="column"
        spacing={12}
        className="EmptyMessageOwnCollection-retailers"
      >
        <Stack
          direction="row"
          spacing={12}
          className="EmptyMessageOwnCollection-retailersRow"
        >
          <AffiliateLink
            href="https://www.arket.com"
            className="EmptyMessageOwnCollection-retailerContainer"
          >
            <img
              src={`${process.env.REACT_APP_STATIC_URL}/empty-collection/arket.png`}
              alt="Arket logo"
              className="EmptyMessageOwnCollection-retailerImage"
            />
          </AffiliateLink>
          <AffiliateLink
            href="https://www.sephora.com"
            className="EmptyMessageOwnCollection-retailerContainer"
          >
            <img
              src={`${process.env.REACT_APP_STATIC_URL}/empty-collection/sephora.png`}
              alt="Sephora logo"
              className="EmptyMessageOwnCollection-retailerImage"
            />
          </AffiliateLink>
          <AffiliateLink
            href="https://www.mango.com"
            className="EmptyMessageOwnCollection-retailerContainer"
          >
            <img
              src={`${process.env.REACT_APP_STATIC_URL}/empty-collection/mango.png`}
              alt="Mango logo"
              className="EmptyMessageOwnCollection-retailerImage"
            />
          </AffiliateLink>
        </Stack>
        <Stack
          direction="row"
          spacing={12}
          className="EmptyMessageOwnCollection-retailersRow"
        >
          <AffiliateLink
            href="https://www.sezane.com"
            className="EmptyMessageOwnCollection-retailerContainer"
          >
            <img
              src={`${process.env.REACT_APP_STATIC_URL}/empty-collection/sezane.png`}
              alt="Sézane logo"
              className="EmptyMessageOwnCollection-retailerImage"
            />
          </AffiliateLink>
          <AffiliateLink
            href="https://www.mytheresa.com"
            className="EmptyMessageOwnCollection-retailerContainer"
          >
            <img
              src={`${process.env.REACT_APP_STATIC_URL}/empty-collection/mytheresa.png`}
              alt="Mytheresa logo"
              className="EmptyMessageOwnCollection-retailerImage"
            />
          </AffiliateLink>
          <AffiliateLink
            href="https://www.nike.com"
            className="EmptyMessageOwnCollection-retailerContainer"
          >
            <img
              src={`${process.env.REACT_APP_STATIC_URL}/empty-collection/nike.png`}
              alt="Nike logo"
              className="EmptyMessageOwnCollection-retailerImage"
            />
          </AffiliateLink>
        </Stack>
      </Stack>
      <Stack alignItems="center">
        <Typography variant="primary.r16" component="p" align="center">
          Browse a full list of{' '}
          {isCreator ? (
            <Link to={R.AFFILIATE_BRANDS}>affiliate brands</Link>
          ) : (
            <Link to={R.RETAILERS}>popular stores</Link>
          )}
        </Typography>

        <Typography
          variant="primary.r16"
          component="p"
          className="EmptyMessageOwnCollection-extensionMessage"
          align="center"
        >
          Add items while browsing any website using the Moonsift{' '}
          <Link
            className="EmptyMessageOwnCollection-extensionLink"
            to={EXTERNAL.CHROMESTORE}
          >
            browser extension
          </Link>{' '}
          or
          <Link
            className="EmptyMessageOwnCollection-extensionLink"
            to={
              isCreator
                ? EXTERNAL.APP_STORE_APP_CREATORS
                : EXTERNAL.APP_STORE_APP
            }
          >
            {' '}
            iOS app
          </Link>
        </Typography>
      </Stack>
    </div>
  );
};

export const EmptyMessageOwnCollection = styled(
  UnstyledEmptyMessageOwnCollection,
)`
  .EmptyMessageOwnCollection-retailerImage {
    transform: scale(0.5);
  }
  .EmptyMessageOwnCollection-retailerContainer {
    border: 1px solid var(--common-black);
    border-radius: 5px;
    width: 98px;
    height: 42px;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .EmptyMessageOwnCollection-retailers {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 24px;
  }
  .EmptyMessageOwnCollection-retailersRow {
    justify-content: center;
  }
  .EmptyMessageOwnCollection-extensionMessage {
    max-width: 286px;
    color: var(--grey-4);
  }
  .EmptyMessageOwnCollection-extensionLink {
    color: var(--grey-4);
    text-decoration: none;
  }
  .EmptyMessageOwnCollection-addItemButton {
    width: 180px;
    height: 269px;
    border: 1px dashed var(--common-black);
    background-color: var(--common-white);
    border-color: var(--grey-2);
    color: var(--common-black);
    border-radius: 5px;
    &:hover {
      border-color: var(--common-black);
      box-shadow: ${({ theme }) => theme.shadows[2]};
    }
  }
  .EmptyMessageOwnCollection-addItemButtonContainer {
    align-items: center;
    width: 100%;
  }
  .EmptyMessageOwnCollection-addCircleIcon {
    width: 36px;
    height: 36px;
    margin-bottom: 8px;
  }
  .EmptyMessageOwnCollection-addItemText {
    margin-bottom: 0px;
  }
`;
