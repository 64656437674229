import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Paper, Stack, Typography } from '@moonsifttech/design-system';

const UnstyledRelinkFacebookMobileCallback = ({
  className,
  style,
}: {
  className?: string;
  style: React.CSSProperties;
}) => {
  return (
    <Stack
      className={className}
      style={style}
      justifyContent="center"
      alignItems="center"
    >
      <Helmet>
        <title>Return to the app - Moonsift</title>
      </Helmet>
      <Paper className="RelinkFacebookMobileCallback-paper">
        <Stack
          className="RelinkFacebookMobileCallback-container"
          direction="column"
          alignItems="center"
        >
          <Stack className="RelinkFacebookMobileCallback-logo" direction="row">
            <img
              className="RelinkFacebookMobileCallback-logoImage"
              src={`${process.env.REACT_APP_STATIC_URL}/logos/logo.svg`}
              alt="Monsift logo"
            />
            <Typography
              className="RelinkFacebookMobileCallback-logoText"
              variant="branded.b42"
            >
              MOONSIFT
            </Typography>
          </Stack>
          <Stack
            justifyContent="center"
            className="RelinkFacebookMobileCallback-explainerContainer"
          >
            <Typography
              variant="primary.b16"
              align="center"
              style={{ marginBottom: '1rem' }}
            >
              We really appreciate your patience.
            </Typography>
            <Typography
              variant="primary.r16"
              align="center"
              style={{ marginBottom: '1rem' }}
            >
              Please now return to the Moonsift app and login with the new
              details you have just provided.
            </Typography>
            <Typography
              variant="primary.r12"
              align="center"
              style={{ marginBottom: '1rem' }}
            >
              NOTE: You are currently in Safari - look for the text "Moonsift"
              on the top left of the screen to return to the Moonsift App.
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};

export const RelinkFacebookMobileCallback = styled(
  UnstyledRelinkFacebookMobileCallback,
)`
  width: 100%;
  height: 100vh;
  background: var(--grey-1);

  .RelinkFacebookMobileCallback-paper {
    padding: 36px 14px;
    width: 480px;
  }

  .RelinkFacebookMobileCallback-logo {
    margin-bottom: 36px;
    align-items: center;

    .RelinkFacebookMobileCallback-logoImage {
      height: 50px;
    }

    .RelinkFacebookMobileCallback-logoText {
      padding: 8px 0 0 4px;
    }
  }

  .RelinkFacebookMobileCallback-explainerContainer {
    max-width: 320px;
  }
`;
